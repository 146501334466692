<template>
  <v-app id="chargersView">
    <v-card>
      <v-card-title>
        <div>
          <b-form-datepicker
            id="start-date"
            v-model="startDate"
            @input="fetchEvents()"
            :max="endDate || new Date()"
            :value-as-date="true"
            placeholder="Start date"
            reset-button
            test-id="start-date-picker"
          ></b-form-datepicker>
        </div>

        <div>
          <b-form-datepicker
            id="end-date"
            v-model="endDate"
            @input="fetchEvents()"
            :min="startDate"
            :value-as-date="true"
            placeholder="End date"
            reset-button
            test-id="end-date-picker"
          ></b-form-datepicker>
        </div>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          hide-details
          solo
          dense
          class="pl-5"
          test-id="search-input"
        ></v-text-field>
      </v-card-title>

      <div class="pl-4 pb-4">
        <div>
          <b-form-checkbox
            id="showChargerWithActiveEvents"
            v-model="showChargersWithActiveEvents"
            test-id="active-events-checkbox"
            >Show only chargers with ongoing downtime events
          </b-form-checkbox>
        </div>

        <div>
          <b-form-checkbox
            id="showChargerWithAffectedCustomers"
            v-model="showChargersWithAffectedCustomers"
            test-id="affected-customers-checkbox"
            >Show only chargers with affected customers
          </b-form-checkbox>
        </div>
      </div>

      <v-data-table
        :loading="isLoading"
        :headers="headers"
        :fields="headers"
        :items="eventsWithChargerSerialNumbers"
        :search="search"
        :expanded.sync="expanded"
        item-key="chargerId"
        expand-on-click
        single-expand
        :footer-props="{
          'items-per-page-options': [20, 40, 60, -1],
        }"
        :items-per-page="20"
        dense
        class="elevation-0"
        @click:row="expandRow"
        test-id="chargers-table"
      >
        <template v-slot:item.totalDuration="{ item }">
          <span>{{ getFormattedDuration(item.totalDuration) }}</span>
        </template>

        <template v-slot:item.serialNumber="{ item }">
          <div
            :data-testid="item.chargerId"
            v-if="item.ongoingDowntimeEvent"
            :class="
              item.ongoingDowntimeEvent.affectedCustomersCount > 0
                ? 'red-alert'
                : 'orange-alert'
            "
          >
            <font-awesome-icon icon="exclamation-circle" />
            <span class="pl-2">{{ item.serialNumber }}</span>
          </div>
          <span v-else :data-testid="item.chargerId">
            {{ item.serialNumber }}
          </span>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-data-iterator :items="item.events">
              <template v-slot:no-data>
                <small>No data</small>
              </template>
              <template v-slot:default="{ items }">
                <b-table
                  striped
                  hover
                  :items="items"
                  :fields="eventHeaders"
                  test-id="downtime-events-table"
                >
                  <template #cell(ongoingDowntimeEvent)="data">
                    <span
                      v-if="data.item.endTime == null"
                      :class="
                        data.item.affectedCustomersCount > 0
                          ? 'red-alert'
                          : 'orange-alert'
                      "
                    >
                      <font-awesome-icon icon="exclamation-circle" />
                      {{ data.item.endDate }}
                    </span>
                  </template>
                  <template #cell(startTime)="data"
                    >{{ getFormattedDate(data.item.startTime) }}
                  </template>
                  <template #cell(endTime)="data"
                    >{{ getFormattedDate(data.item.endTime) }}
                  </template>
                  <template #cell(durationInMinutes)="data"
                    >{{ getFormattedDuration(data.item.durationInMinutes) }}
                  </template>
                </b-table>
              </template>
            </v-data-iterator>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-app>
</template>

<script>
import { subMinutes, differenceInMilliseconds } from "date-fns";
import moment from "moment";
import { getEvents } from "@/api/ChargerDowntimeEventsApi";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { generateChargerSerialNumberMap } from "@/mixins/charger_utils";
import { getChargers } from "@/api/ChargersListApi";

library.add(faExclamationCircle);

export default {
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
  name: "chargerDowntimeEventsTable",
  data() {
    return {
      startDate: moment().subtract(30, "days").toDate(),
      endDate: moment().toDate(),
      showChargersWithActiveEvents: false,
      showChargersWithAffectedCustomers: false,
      search: "",
      expanded: [],
      statistics: [],
      chargerSerialNumberMap: {},
      singleExpand: false,
      isLoading: true,
      eventHeaders: [
        {
          key: "ongoingDowntimeEvent",
          label: "",
        },
        {
          key: "startTime",
          label: "Start",
        },
        {
          key: "endTime",
          label: "End",
        },
        {
          key: "durationInMinutes",
          label: "Duration",
        },
        {
          key: "affectedCustomersCount",
          label: "Affected customers count",
        },
      ],
      headers: [
        {
          text: "Charger id",
          align: "start",
          sortable: true,
          value: "serialNumber",
          class: this.$options.headerStyling,
        },
        {
          text: "Total downtime",
          value: "totalDuration",
        },
        {
          text: "",
          value: "ongoingDowntimeEvent",
          filter: this.showChargersWithActiveEventsFilter,
          align: " d-none",
        },
        {
          text: "",
          value: "events",
          filter: this.showChargersWithAffectedCustomersFilter,
          align: " d-none",
        },
      ],
    };
  },
  methods: {
    async fetchData() {
      await this.fetchChargers();
      this.fetchEvents();
    },
    fetchChargers() {
      this.isLoading = true;
      getChargers()
        .then((response) => {
          this.chargerSerialNumberMap = generateChargerSerialNumberMap(
            response.data
          );
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    fetchEvents() {
      this.isLoading = true;
      getEvents(this.startDate, this.endDate)
        .then((response) => {
          let statistics = response.data.statistics;
          statistics.forEach((chargerStatistics) => {
            chargerStatistics.events.sort(
              (a, b) => new Date(b.startTime) - new Date(a.startTime)
            );
          });
          this.statistics = statistics;
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    showChargersWithActiveEventsFilter(ongoingEvent) {
      if (!this.showChargersWithActiveEvents) {
        return true;
      }

      return ongoingEvent;
    },
    showChargersWithAffectedCustomersFilter(events) {
      if (!this.showChargersWithAffectedCustomers) {
        return true;
      }

      return events.some((event) => event.affectedCustomersCount > 0);
    },
    getFormattedDuration(value) {
      const hours = Math.floor(value / 60);
      const minutes = Math.floor(value % 60);
      if (hours === 0) {
        return `${minutes}m`;
      }
      return `${hours}h ${minutes}m`;
    },
    getFormattedDate(date) {
      if (!date) {
        return "";
      }

      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    expandRow(item, slot) {
      slot.expand(!slot.isExpanded);
    },
  },
  computed: {
    eventsWithChargerSerialNumbers() {
      return [...this.statistics].map((s) => {
        return {
          serialNumber: this.chargerSerialNumberMap[s.chargerId] || s.chargerId,
          ...s,
        };
      });
    },
  },
  mounted() {
    this.fetchData();
    const tokenExpiryDate = localStorage.getItem("tokenExpiry");
    const twoMinutesBeforeExpiry = subMinutes(Date.parse(tokenExpiryDate), 2);
    const diff = differenceInMilliseconds(twoMinutesBeforeExpiry, Date.now());
    if (diff > 0) {
      setInterval(() => location.reload(), diff);
    }
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>

<style>
.timeAgo span {
  padding-left: 12px;
  font-size: 0.7rem;
  color: lightslategray;
  font-style: normal;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif;
}

.red-alert {
  color: red;
  font-weight: bolder;
}

.orange-alert {
  color: orange;
  font-weight: bold;
}

.b-table-details td {
  padding-left: 25px;
  background-color: #f9f5f5;
}

tr:focus {
  outline: none;
}
</style>
<style scoped>
.table > tbody > tr.b-table-row-selected,
.table > tbody > tr.b-table-row-selected > td,
.table > tbody > tr.b-table-row-selected > th {
  background-color: #f5f5f5;
}
</style>
