import { OCPP_API } from "@/api/common/http-common";
import moment from "moment/moment";

export function getEvents(startDate, endDate) {
  const tenant = process.env.VUE_APP_TENANT;
  const start = startDate
    ? `startTime=${moment(startDate).startOf("day").toISOString()}`
    : null;
  const end = endDate
    ? `endTime=${moment(endDate).endOf("day").toISOString()}`
    : null;

  const requestParams = [start, end].filter((param) => param).join("&");

  return OCPP_API.get(
    `/${tenant}/store/v1.0/tenants/${tenant}/chargepointDowntimeEvents?${requestParams}`
  );
}
